import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby"; // to query for image data
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";

const PhotoGallery = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentImage, setCurrentImage] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/bilder/galleri" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid
                aspectRatio
                srcSet
              }
            }
          }
        }
      }
    }
  `);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    onOpen();
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    onClose();
  };

  const photos = data.allFile.edges.map((edge) => {
    console.log(edge.node.childImageSharp.fluid.aspectRatio);

    return {
      src: edge.node.childImageSharp.fluid.src,
      srcSet: edge.node.childImageSharp.fluid.srcSet,
      width: edge.node.childImageSharp.fluid.aspectRatio,
      height: 1,
    };
  });

  return (
    <>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={photos} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

const Embed = ({ url }) => {
  return (
    <Box
      mx={"auto"}
      textAlign="center"
      // pt={12}
      position="relative"
      style={{
        paddingBottom: "56.25%" /* 16:9 */,
      }}
      // height="200px"
    >
      <iframe
        // width="660"
        src={url}
        title="YouTube video player"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99,
        }}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </Box>
  );
};

const StreamHeading = ({ children }) => {
  return (
    <Heading as="h4" color="white" fontSize="2xl" mb={4} fontFamily="courgette">
      {children}
    </Heading>
  );
};

const SectionHeading = ({ children }) => {
  return (
    <Heading as="h2" mb={8} mt={8} textAlign="center" fontFamily="courgette">
      {children}
    </Heading>
  );
};

function Livestream() {
  return (
    <Box bg="black" py={12}>
      <Container maxW="container.xl">
        <Stack direction={["column", "column", "row"]}>
          <Box flex={1} mb={[8, 8, 0]}>
            <StreamHeading>With audio</StreamHeading>
            <Embed url={"https://www.youtube.com/embed/it9gh4YRzdA"} />
          </Box>
          <Box flex={1}>
            <StreamHeading>Missing audio, with comments</StreamHeading>
            <Embed url="https://www.youtube.com/embed/A8cWTtExBTU" />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Grattis Ulf & Paloma!</title>
        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Courgette&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Container maxW="container.lg" py={20}>
        <Box justifyContent="center">
          <StaticImage
            src="../images/bilder/thank-you.jpg"
            alt="Thank you, Gracias"
            layout="fullWidth"
          />
        </Box>
      </Container>
      <Box textAlign="center">
        <SectionHeading>Ceremony</SectionHeading>
        <Text
          mt={[-4, -4, -8]}
          mb={4}
          color="#444"
          fontStyle="italic"
          textAlign={["center", "center", "left"]}
          ml={[0, 0, 4]}
        >
          Photos by{" "}
          <Text as="span" fontWeight="bold">
            Edvardas Gongapsevas
          </Text>
        </Text>
        <Livestream />
      </Box>
      <section>
        {/* <SectionHeading>Image gallery</SectionHeading> */}
        <PhotoGallery />
      </section>
      <section>
        {/* <SectionHeading>Image gallery</SectionHeading> */}
        <SectionHeading>Congratulation videos</SectionHeading>
        <Box bg="black" py={12}>
          <Container maxW="container.xl">
            <Stack direction={["column", "column", "row"]}>
              <Box flex={1} mb={[8, 8, 0]}>
                <Embed url="https://www.youtube.com/embed/z-utpEZrtlE" />
              </Box>
              <Box flex={1} mb={[8, 8, 0]}>
                <Embed url="https://www.youtube.com/embed/dxduGY7HgOQ" />
              </Box>
            </Stack>
          </Container>
        </Box>
      </section>
    </main>
  );
};

export default IndexPage;
